import { TelegramAuthData } from "@telegram-auth/react";

import { CheckTokenResType, GetTokenResType } from "./types";

import { request } from "../axios";

export const authService = {
  checkAuth: () => {
    return request
      .get<CheckTokenResType>("/auth/check")
      .then((res) => res.data);
  },
  getToken: (data: TelegramAuthData) => {
    return request
      .post<GetTokenResType>("/auth/token", data)
      .then((res) => res.data);
  },
};
