import { Button } from 'antd';
import Cookies from 'universal-cookie';
import { useLocation, useNavigate } from 'react-router-dom';

import { ACCESS_TOKEN_KEY, WIDGET_NAME_DEV, WIDGET_NAME_PROD } from '../../../features/constants';

const widgetName = process.env.NODE_ENV === 'development' ? WIDGET_NAME_DEV : WIDGET_NAME_PROD;

export const Header = () => {
  const navigate = useNavigate();
  const cookies = new Cookies();
  const locate = useLocation();

  const handleLogout = () => {
    cookies.remove(ACCESS_TOKEN_KEY);
    document.getElementById(widgetName)?.remove();
    window.location.reload(); // Перезагружаем страницу
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="m-2 flex justify-between items-center gap-3">
      <div>
        {locate.pathname !== '/' && (
          <Button type="link" onClick={handleBack}>
            Назад
          </Button>
        )}
      </div>

      <Button danger onClick={handleLogout}>
        Выход
      </Button>
    </div>
  );
};
