import { useQuery } from "@tanstack/react-query";
import Cookies from "universal-cookie";

import {
  ACCESS_TOKEN_KEY,
  QUERY_KEY_CHECK_AUTH,
} from "../../../features/constants";
import { authService } from "..";

export const useChechAuth = () => {
  return useQuery({
    queryKey: [QUERY_KEY_CHECK_AUTH],
    queryFn: () => {
      const cookies = new Cookies();

      if (cookies.get(ACCESS_TOKEN_KEY)) {
        return authService.checkAuth();
      } else {
        return null;
      }
    },
  });
};
