import { createBrowserRouter } from "react-router-dom";

import { MainPage } from "../pages/main";
import { LocaleEditPage } from "../pages/locale-edit";
import AuthGuard from "../components/layout/auth-guard";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthGuard>
        <MainPage />
      </AuthGuard>
    ),
  },
  {
    path: "/locale/:id",
    element: (
      <AuthGuard>
        <LocaleEditPage />
      </AuthGuard>
    ),
  },
]);
