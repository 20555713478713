import { useQuery } from '@tanstack/react-query';

import { serviceLocales } from '..';
import { QUERY_KEY_GET_LOCALE_BY_ID } from '../../../features/constants';

export const useGetLocaleById = (id: string) => {
  return useQuery({
    queryKey: [QUERY_KEY_GET_LOCALE_BY_ID, id],
    queryFn: () => serviceLocales.getLocaleById(id),
  });
};
