import { Divider } from "antd";

import { LocaleList } from "./locale-list";

export const Locales = () => {
  return (
    <div className="w-full h-full p-3">
      <Divider>Локали</Divider>
      <LocaleList />
    </div>
  );
};
