import { Button, Table } from 'antd';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';

import { useGetLocales } from '../../../service/locales/hooks';
import { Loader } from '../../common/loader';

export const LocaleList = () => {
  const { data: locales, isPending: isPendingGetLocales } = useGetLocales();

  if (isPendingGetLocales) return <Loader />;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '30%',
    },
    {
      title: 'Название',
      dataIndex: 'name',
    },
    {
      title: '',
      dataIndex: 'edit',
      width: '6%',
    },
  ];

  const dataSource = locales?.map((locale) => ({
    id: locale.id,
    name: locale.name,
    edit: (
      <Link to={`/locale/${locale.id}`}>
        <Button>
          <EditOutlined />
        </Button>
      </Link>
    ),
  }));

  return (
    <div>
      <Table columns={columns} dataSource={dataSource} />
    </div>
  );
};
