import { CreateLocaleType, UpdateLocaleType } from './types';

import { LocaleType } from '../../types/locale';
import { request } from '../axios';

export const serviceLocales = {
  getLocales: () => {
    return request.get<LocaleType[]>('/locales').then((res) => res.data);
  },
  getLocaleById: (id: string) => {
    return request.get<LocaleType>(`/locales/${id}`).then((res) => res.data);
  },
  updateLocale: ({ id, ...rest }: UpdateLocaleType) => {
    return request.patch(`/locales/${id}`, { ...rest }).then((res) => res.data);
  },
  createLocale: (data: CreateLocaleType) => {
    return request.post('/locales', data).then((res) => res.data);
  },
  removeLocale: (id: string) => {
    return request.delete(`/locales/${id}`).then((res) => res.data);
  },
};
