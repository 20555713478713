import { CreateFeatureType, UpdateFeatureType } from "./types";

import { request } from "../axios";

export const featuresService = {
  updateFeature: ({ id, ...rest }: UpdateFeatureType) => {
    return request
      .patch(`/features/${id}`, { ...rest })
      .then((res) => res.data);
  },
  removeFeature: (id: string) => {
    return request.delete(`/features/${id}`).then((res) => res.data);
  },
  createFeature: ({ name, localeId }: CreateFeatureType) => {
    return request.post(`/features`, { name, localeId }).then((res) => res.data);
  },
};
