import { useMutation } from "@tanstack/react-query";
import { TelegramAuthData } from "@telegram-auth/react";

import { authService } from "..";

export const useGetToken = () => {
  return useMutation({
    mutationFn: (data: TelegramAuthData) => {
      return authService.getToken(data);
    },
  });
};
