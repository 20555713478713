import { useParams } from "react-router-dom";

import { useGetLocaleById } from "../service/locales/hooks";
import { Loader } from "../components/common/loader";
import { LocaleEdit } from "../components/pages/locale-edit";

export const LocaleEditPage = () => {
  const { id } = useParams();

  const { data, isPending } = useGetLocaleById(id || "");

  if (isPending) return <Loader />;

  if (!data) return <p>Произошла ошибка</p>;

  return (
    <div>
      <LocaleEdit locale={data} />
    </div>
  );
};
